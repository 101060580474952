import React from 'react'
import CreateItemButton from '../../Components/Primitives/CreateItemButton'
import { Route } from '../../Utilities/Route'
import { useAsync } from 'react-use'
import { Organization } from '../../Utilities/types'
import OrganizationItem from '../../Components/OrganizationItem'
import * as api from '../../Utilities/api'
import { Spin } from 'antd'
import { Link } from 'react-router-dom'

const Organizations: React.FC = () => {
  const async = useAsync(async() => await api.organizations.fetch(undefined, undefined))
  const orgs = async.value! as Organization[]

  if (async.loading) {
    return (
      <Spin size='large' spinning={async.loading} style={{ margin: '4rem 0' }}/>
    )
  }

  if (async.error) {
    return (
      <div>
        <p>{async.error.message}</p>
        <p>
          No projects found, click{' '}
          <Link to={Route.organizations_create}>here</Link> to create one
        </p>
      </div>
    )
  }

  return <>
    <header>
      <h1 className='page-title-text'>Organizations</h1>
      <CreateItemButton to={Route.organizations_create} >Create Organization</CreateItemButton>
    </header>
    { orgs.map(org => <OrganizationItem org={org} key={org.id}/>) }
  </>
}

export default Organizations
