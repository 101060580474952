import React from 'react'
import CreateItemButton from '../../Components/Primitives/CreateItemButton'
import QuoteItem from '../../Components/QuoteItem'
import { Route } from '../../Utilities/Route'
import { useAsync } from 'react-use'
import * as api from '../../Utilities/api'
import { Quote } from '../../Utilities/types'

const Quotes: React.FC = () => {
  const async = useAsync(async() => await api.quotes.fetch(undefined, undefined))

  if (async.loading) {
    return <p>Loading...</p>
  }

  if (async.error) {
    return <p>{async.error.message}</p>
  }

  const quotes = async.value! as Quote[]

  return <>
    <header>
      <h1 className='page-title-text'>Quotes</h1>
      <CreateItemButton to={Route.quotes_create} >Create Quote</CreateItemButton>
    </header>
    { quotes.map((q: Quote) => <QuoteItem quote={q} key={q.id} />) }
  </>
}

export default Quotes
