import React from 'react'
import { Quote } from '../../../Utilities/types'
import { useForm } from 'react-hook-form'
import { quotes } from '../../../Utilities/api'
import useToast from '../../../Utilities/Hooks/useToast'

export const QuoteForm: React.FC<{ quote?: Quote }> = ({ quote }) => {
  const addToast = useToast()
  const { handleSubmit, register } = useForm({ defaultValues: quote })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <label>
        Quote
        <textarea {...register('quote', { required: true })} />
      </label>
      <label>
        Name
        <input {...register('by.name')} />
      </label>
      <label>
        Role
        <input {...register('by.role')} />
      </label>
      <label>
        Organization
        <input {...register('by.organization')} />
      </label>
      <label>
        City
        <input {...register('location.city')} />
      </label>
      <label>
        State
        <input {...register('location.state')} />
      </label>
      <label>
        Country
        <input {...register('location.country', { required: true })} />
      </label>
      <input type='submit' value='Submit' />
    </form>
  )

  async function submit(data: any) {
    try {
      if (quote?.id) {
        await quotes.quote.update({ id: quote.id, createdAt: quote.createdAt, ...data })
      } else {
        await quotes.quote.create(data)
      }
      addToast(`Quote successfully ${quote?.id ? 'updated' : 'created'}`, 'success')
    } catch (error) {
      addToast(error as Error)
    }
  }
}

const CreateQuote: React.FC = () => {
  return (
    <>
      <header>
        <h1>Create Quote</h1>
      </header>
      <QuoteForm />
    </>
  )
}

export default CreateQuote
