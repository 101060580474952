import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { compact } from 'lodash'
import { onlyTruthy, now, isoString, dateFromISO, colorForCauseArea, descForCauseArea, copyForCauseArea } from './etc'
import { $enum } from 'ts-enum-util'
import { Quote, Organization, Project, User, ProjectStatus, UserRole, CauseAreas, Ticket } from './types'
import { auth, functions } from './firebase'

const firestore = firebase.firestore()
export interface Where {
  fieldPath: string | firebase.firestore.FieldPath
  opStr: firebase.firestore.WhereFilterOp
  value: any
}

export interface Order {
  fieldPath: string | firebase.firestore.FieldPath
  directionStr?: 'desc' | 'asc' | undefined
}

const notImplemented = new Error('Not implemented')
const noDocumentForQuery = new Error('No data exists for this query')

// authentication methods
export const authentication = {
  isAdmin: () => { throw notImplemented },
  signIn: async(email: string, password: string): Promise<void | firebase.auth.UserCredential> => {
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    return await auth.signInWithEmailAndPassword(email, password)
  },
  currentUser: () => auth.currentUser?.uid,
  signOut: () => { return auth.signOut() }
}

// user methods
export const users = {
  user: {
    fetch: async(id: string): Promise<User> => {
      const doc = await firestore.collection('users').doc(id).get()
      if (!doc.exists) throw noDocumentForQuery
      const user = { id: doc.id, ...doc.data() } as User
      if (user.birthdate) {
        user.birthdate = dateFromISO(user.birthdate)
      }
      return user
    },
    role: async(id: string): Promise<UserRole> => {
      const rsp = await functions.httpsCallable('role')(id)
      return rsp.data.userRole as UserRole
    },
    promote: async(id: string, to: UserRole): Promise<void> => {
      await functions.httpsCallable('promote')({ promoteeId: id, role: to })
    }
  },
  fetch: async(where: Where | undefined, order: Order | undefined): Promise<User[]> => {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> = firestore.collection('users')
    if (where) {
      query = query.where(where.fieldPath, where.opStr, where.value)
    }
    if (order) {
      query = query.orderBy(order.fieldPath, order.directionStr)
    }
    const snapshot = await query.get()
    const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as User))
    return compact(users)
  },
  fetchSubscribed: async(): Promise<User[]> => {
    const query = firestore.collection('users').where('newsletter', '==', true)
    const snapshot = await query.get()
    const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as User))
    return compact(users)
  },
  updateSubscription: async(id: string, subscribed: boolean): Promise<void> => {
    await firestore.collection('users').doc(id).update({ newsletter: subscribed })
  }
}

// quote methods
export const quotes = {
  quote: {
    create: (quote: Omit<Quote, 'id' | 'createdAt' >) =>
      firestore.collection('quotes').add(onlyTruthy({
        ...quote,
        createdAt: now()
      })),
    update: (quote: Quote) => {
      const q = onlyTruthy({
        ...quote,
        editedAt: now()
      })
      delete q.id
      return firestore.collection('quotes').doc(quote.id).set(q)
    },
    fetch: (id?: string) => documentQuery(firestore.collection('quotes').doc(id))
  },
  fetch: (where: Where[] | undefined, order: Order | undefined) =>
    collectionQuery(firestore.collection('quotes'), where, order)
}

// organization methods
export const organizations = {
  org: {
    create: (org: Omit<Organization, 'id' | 'createdAt' >) => {
      firestore.collection('organizations').add(onlyTruthy({
        ...org,
        createdAt: now()
      }))
    },
    fetch: async(id: string) => documentQuery(firestore.collection('organizations').doc(id))
  },
  fetch: (where: Where[] | undefined, order: Order | undefined) =>
    collectionQuery(firestore.collection('organizations'), where, order)
}

// causeAreas methods
export const causeAreas = {
  create: async() => {
    const promises = $enum(CauseAreas).map((ca, _, __, index) => {
      const colors = colorForCauseArea(ca)
      return firestore.collection('causeAreas').doc(ca).set({
        color: colors?.lightTheme,
        darkTheme: colors?.darkTheme,
        lightTheme: colors?.lightTheme,
        description: descForCauseArea(ca),
        goal: copyForCauseArea(ca),
        number: index + 1
      })
    })
    return await Promise.all(promises)
  }
}

// project methods
export const projects = {
  project: {
    create: async(project: Omit<Project, 'id' | 'createdAt'>) => {
      const rsp = await firestore.collection('projects').add(
        onlyTruthy({
          ...project,
          status: 'draft',
          signUpDeadline: isoString(project.signUpDeadline),
          eventDate: {
            start: isoString(project.eventDate.start),
            end: isoString(project.eventDate.end)
          },
          attendees: [],
          createdAt: now(),
          waivers: project.waivers // Include waivers in the creation
        })
      )
      return rsp.id
    },
    update: (project: Project) => {
      // TODO check values that changed and update those only
      const p = onlyTruthy({
        ...project,
        signUpDeadline: isoString(project.signUpDeadline),
        eventDate: {
          start: isoString(project.eventDate.start),
          end: isoString(project.eventDate.end)
        },
        attendees: project.attendees.map((p) => ({
          ...p,
          signupDate: isoString(p.signupDate)
        })),
        editedAt: now(),
        waivers: project.waivers // Include waivers in the update
      })
      delete p.id
      return firestore.collection('projects').doc(project.id).set(p)
    },
    setStatus: async(id: string, status: ProjectStatus) => {
      await firestore.collection('projects').doc(id).update({ status })
      return status
    },
    fetch: async(id?: string) => {
      const rsp = (await documentQuery(
        firestore.collection('projects').doc(id)
      )) as Project
      rsp.eventDate.start = dateFromISO(rsp.eventDate.start)
      rsp.eventDate.end = dateFromISO(rsp.eventDate.end)
      rsp.signUpDeadline = dateFromISO(rsp.signUpDeadline)
      rsp.attendees = rsp.attendees.map((p) => ({
        ...p,
        signupDate: dateFromISO(p.signupDate)
      }))
      return rsp
    }
  },
  fetch: (where: Where[] | undefined, order: Order | undefined) =>
    collectionQuery(firestore.collection('projects'), where, order)
}

// tickets methods
export const tickets = {
  ticket: {
    update: async(userId: string, ticket: Ticket) => {
      const t = onlyTruthy({
        ...ticket,
        editedAt: now()
      })
      delete t.id
      return firestore.collection('tickets').doc(userId).update({
        [`${ticket.id}`]: t
      })
    },
    resolve: async(userId: string, ticketId: string, comments: string) => {
      await firestore.collection('tickets').doc(userId).update({
        [`${ticketId}.status`]: 'resolved',
        [`${ticketId}.comment`]: comments
      })
    },
    fetch: async(userId: string, ticketId: string) => {
      const doc = await firestore.collection('tickets').doc(userId).get()
      const data = doc.data()
      if (data && data[ticketId]) {
        return { id: ticketId, ...data[ticketId] } as Ticket
      }
      throw new Error('Ticket not found')
    }
  },
  fetchAll: async() => {
    const snapshot = await firestore.collection('tickets').get()
    const tickets: Ticket[] = []
    snapshot.forEach(doc => {
      const userTickets = doc.data()
      Object.keys(userTickets).forEach(ticketId => {
        tickets.push({ id: ticketId, ...userTickets[ticketId] })
      })
    })
    return tickets
  }
}

async function collectionQuery(collection: firebase.firestore.CollectionReference, where: Where[] | undefined, order: Order | undefined) {
  let query: firebase.firestore.Query<firebase.firestore.DocumentData> = collection
  if (where) {
    where.forEach((w: Where) => {
      query = query.where(w.fieldPath, w.opStr, w.value)
    })
  }
  if (order) {
    query = query.orderBy(order.fieldPath, order.directionStr)
  }

  const snapshot = await query.get()
  const docs = snapshot.docs
  return compact(docs.map((doc: any) => {
    if (!doc.exists) { return null }
    return { id: doc.id, ...doc.data() }
  }))
}

async function documentQuery(document: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) {
  const doc = await document.get()
  if (!doc.exists) { throw noDocumentForQuery }
  return { id: doc.id, ...doc.data() }
}
