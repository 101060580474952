import { useEffect } from 'react'

export default function useFullMainArea() {
  useEffect(() => {
    const el = document.getElementById('main')
    if (!el) return

    const originalStyles = {
      maxWidth: el.style.maxWidth,
      padding: el.style.padding
    }

    const newStyles = {
      maxWidth: 'unset',
      padding: '0'
    }

    Object.assign(el.style, newStyles)

    return () => {
      Object.assign(el.style, originalStyles)
    }
  }, [])
}
