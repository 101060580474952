import React, { useState } from 'react'
import styles from './index.module.scss'
import { Organization } from '../../../Utilities/types'
import { useForm, FormProvider } from 'react-hook-form'
import useToast from '../../../Utilities/Hooks/useToast'
import * as api from '../../../Utilities/api'
import AddressInput from '../../../Components/Primitives/AddressInput'
import ImageUploader from '../../../Components/ImageUploader'
import { placeholderURL } from '../../../Utilities/etc'
import { storage } from '../../../Utilities/firebase'
import { useNavigate } from 'react-router-dom'
import { Route } from '../../../Utilities/Route'

export const OrganizationForm: React.FC<{ org?: Organization }> = ({ org }) => {
  const form = useForm({ defaultValues: org })
  const { register, handleSubmit, control } = form
  const [disabled, setDisabled] = useState(!!org)
  const addToast = useToast()
  const navigate = useNavigate()

  return (
    <>
      <div className={styles.container}>
        <FormProvider {...form}>
          <aside>
            <ImageUploader
              name='logo'
              control={control}
              baseStorageRef={storage.child('organization-logos')}
              placeholder={placeholderURL('Upload a logo for this organization', 250, 250)}
            />
            {org && (
              <button onClick={() => navigate(`${Route.organizations}/${org.id}${Route.projects_create}`)}>
                Add Project
              </button>
            )}
          </aside>
          <form onSubmit={handleSubmit(submit)} id='organization-form'>
            <fieldset disabled={disabled}>
              <header>
                <h1>
                  <input {...register('name', { required: true })} placeholder='Organization Name' aria-label='Organization Name' />
                </h1>
              </header>
              <section>
                <h3 className='medium-black-text'>About</h3>
                <hr className='custom-hr' />
                <label className='h5-label horizontal'>
                  Organization Website
                  <input {...register('website')} placeholder='Website' type='url' />
                </label><br />
                <label className='h5-label horizontal'>
                  Organization Phone
                  <input {...register('phone')} placeholder='Phone' type='tel' />
                </label><br />
                <label className='h5-label horizontal'>
                  Organization Email
                  <input {...register('email')} placeholder='Email' type='email' />
                </label>

                <h3 className='medium-black-text'>Registered Address</h3>
                <hr className='custom-hr' />
                <AddressInput name='registeredAddress' className={styles.addressContainer} />
                <label className='h5-label'>
                  About the organization
                  <textarea {...register('about', { required: true })} placeholder='About' />
                </label>
              </section>
              <input
                type='submit'
                value={disabled ? 'Edit Details' : org ? 'Save Changes' : 'Create Organization'}
                form='organization-form'
              />
            </fieldset>
          </form>
        </FormProvider>
      </div>
    </>
  )

  async function submit(data: any) {
    setDisabled(true)
    try {
      await api.organizations.org.create(data)
      addToast(`Organization: ${data.name} was successfully created`)
    } catch (error) {
      addToast(error as Error)
    } finally {
      setDisabled(false)
    }
  }
}

const CreateOrganizations: React.FC = () => {
  return <OrganizationForm />
}

export default CreateOrganizations
