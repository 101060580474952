import React from 'react'
import Nav from './Nav'
import { Routes, Route } from 'react-router-dom'
import FourOhFour from './Components/Primitives/404'
import { $enum } from 'ts-enum-util'
import { Route as BaftRoute } from './Utilities/Route'
import componentForRoute from './Utilities/componentForRoute'
import Footer from './Footer'
import ErrorBoundary from './Components/Primitives/ErrorBoundary'
import useGooglePlaces from './Utilities/Hooks/useGooglePlaces'

const App: React.FC = () => {
  const { loadError } = useGooglePlaces()
  if (loadError) console.error(loadError)

  return (
    <ErrorBoundary>
      <header>
        <Nav />
      </header>
      <main>
        <Routes>
          {
            $enum(BaftRoute).map(route => {
              const Component = componentForRoute(route)
              return Component ?? null
            })
          }
          <Route path="*" element={<FourOhFour />} />
        </Routes>
      </main>
      <Footer />
    </ErrorBoundary>
  )
}

export default App
