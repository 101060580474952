import React from 'react'
import styles from './DatePicker.module.scss'
import { Controller, Control } from 'react-hook-form'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'

interface DatePickerProps extends Omit<ReactDatePickerProps, 'onFocus' | 'onBlur' | 'onChange' | 'placeholderText' | 'selected' | 'disabled'> {
  name: string
  defaultValue?: any
  placeholder?: string
  editing?: boolean
  control?: Control<any>
}

export const DatePicker: React.FC<DatePickerProps> = ({ placeholder, ...props }) => {
  return (
    <Controller
      {...props}
      render={({ field }) => (
        <ReactDatePicker
          placeholderText={placeholder}
          selected={field.value}
          showYearDropdown
          showMonthDropdown
          yearDropdownItemNumber={10}
          disabledKeyboardNavigation
          dateFormat={`MMMM d, yyyy${props.showTimeSelect ? ' h:mm aa' : ''}`}
          wrapperClassName={styles.wrapper}
          className={styles.popper}
          {...field}
          {...props}
        />
      )}
    />
  )
}

export default DatePicker
