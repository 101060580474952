import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Route as BaftRoute } from '../../../Utilities/Route'
import { authentication } from '../../../Utilities/api'

interface PrivateRouteProps {
  children: React.ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const location = useLocation()

  if (!authentication.currentUser()) {
    return <Navigate to={BaftRoute.home} state={{ terminus: location.pathname }} />
  }

  return <>{children}</>
}

export default PrivateRoute
