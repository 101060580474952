import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/dropdown/style/index.css'
import 'antd/lib/empty/style/index.css'
import 'antd/lib/pagination/style/index.css'
import 'antd/lib/select/style/index.css'
import 'antd/lib/spin/style/index.css'
import 'antd/lib/style/index.css'
import 'antd/lib/table/style/index.css'
import 'antd/lib/tooltip/style/index.css'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)
  root.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  )
}

serviceWorker.unregister()

reportWebVitals()
