import React from 'react'
import styles from './NewsletterItem.module.scss'
import { User } from '../Utilities/types'
import { fullName } from '../Utilities/etc'

const NewsletterItem: React.FC<{ user: User, onUnsubscribe: (id: string) => void }> = ({ user, onUnsubscribe }) => {
  const handleUnsubscribe = () => {
    onUnsubscribe(user.id)
  }
  return (
    <div className={styles.container}>
      <div>
        <h5>Name</h5>
        {user.name ? fullName(user.name) : ''}
      </div>
      <div>
        <h5>Email</h5>
        {user.email || ''}
      </div>
      <div>
        <h5>Status</h5>
        Subscribed
      </div>
      <div>
        <h5>Action</h5>
        <button className='small-button' onClick={handleUnsubscribe}>
          Unsubscribe
        </button>
      </div>
    </div>
  )
}

export default NewsletterItem
