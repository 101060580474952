import React from 'react'
import UserItem from '../../Components/UserItem'
import { useAsync } from 'react-use'
import * as api from '../../Utilities/api'
import { Spin } from 'antd'

const Users: React.FC = () => {
  const async = useAsync(async() => api.users.fetch(undefined, undefined))

  if (async.loading) return <Spin size='large' className='centered'/>
  if (async.error) return <p>{async.error.message}</p>

  const users = async.value!

  return <>
    <header>
      <h1 className='page-title-text'>Users</h1>
    </header>
    { users.map((u) => <UserItem user={u} key={u.id}/>) }
  </>
}

export default Users
