import React, { ReactElement } from 'react'
import PrivateRoute from '../Components/Primitives/Auth/PrivateRoute'
import { Route as BaftRoute } from './Route'
import { Route, Routes } from 'react-router-dom'
import Landing from '../Routes'
import Stats from '../Routes/stats'
import Quotes from '../Routes/quotes'
import Users from '../Routes/users'
import Newsletter from '../Routes/newsletter'
import Organizations from '../Routes/organizations'
import Tickets from '../Routes/tickets'
import Projects from '../Routes/projects'
import CreateQuote from '../Routes/quotes/create'
import EditQuote from '../Routes/quotes/ID'
import CreateOrganizations from '../Routes/organizations/create'
import CreateProject from '../Routes/projects/create'
import Project from '../Routes/projects/ID'
import Organization from '../Routes/organizations/ID'
import UserProfile from '../Routes/users/ID'
// import Ticket from '../Routes/tickets'

function componentForRoute(route: BaftRoute): ReactElement | null {
  const additionalProps: any = { key: route, path: route }
  const element = raw()

  if (element.props.path) {
    // don't overwrite any explicit choice
    delete additionalProps.path
  }

  return React.cloneElement(element, additionalProps)

  function raw(): ReactElement {
    switch (route) {
    case BaftRoute.home:
      return <Route path={BaftRoute.home} element={<Landing />} />
    case BaftRoute.projects:
      return (
        <Route
          path={`${route}/*`}
          element={
            <PrivateRoute>
              <Routes>
                <Route path=":id" element={<Project />} />
                <Route path="*" element={<Projects />} />
              </Routes>
            </PrivateRoute>
          }
        />
      )
    case BaftRoute.projects_create:
      return (
        <Route
          path={BaftRoute.projects_create}
          element={
            <PrivateRoute>
              <CreateProject />
            </PrivateRoute>
          }
        />
      )
    case BaftRoute.organizations_create:
      return (
        <Route
          path={BaftRoute.organizations_create}
          element={
            <PrivateRoute>
              <CreateOrganizations />
            </PrivateRoute>
          }
        />
      )
    case BaftRoute.organization_projects_create:
      return (
        <Route
          path={route}
          element={
            <PrivateRoute>
              <CreateProject />
            </PrivateRoute>
          }
        />
      )
    case BaftRoute.organizations:
      return (
        <Route
          path={`${route}/*`}
          element={
            <PrivateRoute>
              <Routes>
                <Route path=":id" element={<Organization />} />
                <Route path="" element={<Organizations />} />
              </Routes>
            </PrivateRoute>
          }
        />
      )
    case BaftRoute.users:
      return (
        <Route
          path={`${route}/*`}
          element={
            <PrivateRoute>
              <Routes>
                <Route path=":id" element={<UserProfile />} />
                <Route path="" element={<Users />} />
              </Routes>
            </PrivateRoute>
          }
        />
      )
    case BaftRoute.newsletter:
      return (
        <Route
          path={BaftRoute.newsletter}
          element={
            <PrivateRoute>
              <Newsletter />
            </PrivateRoute>
          }
        />
      )
    case BaftRoute.quotes_create:
      return (
        <Route
          path={BaftRoute.quotes_create}
          element={
            <PrivateRoute>
              <CreateQuote />
            </PrivateRoute>
          }
        />
      )
    case BaftRoute.quotes:
      return (
        <Route
          path={`${route}/*`}
          element={
            <PrivateRoute>
              <Routes>
                <Route path=":id" element={<EditQuote />} />
                <Route path="" element={<Quotes />} />
              </Routes>
            </PrivateRoute>
          }
        />
      )
    case BaftRoute.stats:
      return (
        <Route
          path={BaftRoute.stats}
          element={
            <PrivateRoute>
              <Stats />
            </PrivateRoute>
          }
        />
      )
    case BaftRoute.tickets:
      return (
        <Route
          path={BaftRoute.tickets}
          element={
            <PrivateRoute>
              <Tickets />
            </PrivateRoute>
          }
        />
      )
    default:
      return <></>
    }
  }
}

export default componentForRoute
