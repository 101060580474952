import React from 'react'
import { QuoteForm } from '../create'
import { useAsync } from 'react-use'
import * as api from '../../../Utilities/api'
import { Quote } from '../../../Utilities/types'
import { useParams } from 'react-router-dom'

const EditQuote: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const async = useAsync(async() => await api.quotes.quote.fetch(id) as Quote)

  if (async.loading) {
    return <p>Loading...</p>
  }

  if (async.error) {
    return <p>{async.error.message}</p>
  }

  return (
    <>
      <header>
        <h1>Edit Quote</h1>
      </header>
      <QuoteForm quote={async.value} />
    </>
  )
}

export default EditQuote
