import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Route } from './Utilities/Route'
import { startCase, startsWith } from 'lodash'
import { classNames } from './Utilities/etc'
import styles from './Nav.module.scss'

enum NavKeys {
  projects = 'projects',
  organizations = 'organizations',
  users = 'users',
  newsletter = 'newsletter',
  quotes = 'quotes',
  stats = 'stats',
  tickets = 'tickets'
}

const Nav: React.FC = () => {
  const active = useLocation().pathname
  return (
    <nav>
      <div className='main-size'>
        <Link to='/'>
          <img src={`${process.env.PUBLIC_URL}/logo-white.svg`} alt='IVolunteer Logo' />
        </Link>

        {Object.keys(NavKeys).map((key: string) => {
          const route = Route[key as keyof typeof NavKeys]
          const isActive = active !== Route.home && startsWith(active, route)
          return (
            <Link
              to={route}
              className={classNames(styles.link, isActive && styles.active)}
              key={key}
            >
              {startCase(key)}
            </Link>
          )
        })}
      </div>
    </nav>
  )
}

export default Nav
