import React from 'react'
import styles from './QuoteItem.module.scss'
import { Quote } from '../Utilities/types'
import { Link } from 'react-router-dom'
import { Route } from '../Utilities/Route'

const QuoteItem: React.FC<{quote: Quote}> = props => {
  const { id, quote, by, location } = props.quote

  const byItems: string[] = []
  byItems.push(`${by?.name || 'Anonymous'}${by?.role || by?.role ? ',' : ''}`)
  if (by?.role) { byItems.push(by.organization ? `${by.role} at` : by.role) }
  if (by?.organization) { byItems.push(by.organization) }

  const byCopy = byItems.join(' ')
  const locationCopy = [location.city, location.state, location.country].filter(i => i).join(', ')

  return <div className={styles.container}>
    <blockquote>{quote}</blockquote>
    <div className={styles.details}>
      <div>
        <div>{byCopy}</div>
        <div>{locationCopy}</div>
      </div>
      <Link to={`${Route.quotes}/${id}`}>Edit Quote</Link>
    </div>
  </div>
}

export default QuoteItem
