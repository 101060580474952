import React, { useState, useCallback, useEffect } from 'react'
import NewsletterItem from '../../Components/NewsletterItem'
import { useAsync } from 'react-use'
import * as api from '../../Utilities/api'
import { Spin } from 'antd'
import { User } from '../../Utilities/types'

const Newsletter: React.FC = () => {
  const { loading, error, value: initialUsers } = useAsync(async() => api.users.fetchSubscribed(), [])
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    if (initialUsers) {
      setUsers(initialUsers)
    }
  }, [initialUsers])

  const handleUnsubscribe = useCallback(async(id: string) => {
    try {
      await api.users.updateSubscription(id, false)
      setUsers((prevUsers) => prevUsers.filter(user => user.id !== id))
      window.location.reload()
    } catch (err) {
      console.error('Failed to unsubscribe user:', err)
    }
  }, [])

  if (loading) return <Spin size='large' className='centered' />
  if (error) return <p>{error.message}</p>

  return (
    <>
      <header>
        <h1 className='page-title-text'>Subscribed Users (Newsletter)</h1>
      </header>
      {users.map((u: User) => (
        <NewsletterItem user={u} key={u.id} onUnsubscribe={handleUnsubscribe} />
      ))}
    </>
  )
}

export default Newsletter
