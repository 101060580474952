import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { useForm, FormProvider } from 'react-hook-form'
import useToast from '../../../Utilities/Hooks/useToast'
import AddressInput from '../../../Components/Primitives/AddressInput'
import SDGSelect from '../../../Components/Primitives/SDGSelect'
import DatePicker from '../../../Components/Primitives/DatePicker'
import ImageUploader from '../../../Components/ImageUploader'
import { storage, users } from '../../../Utilities/firebase'
import { projects } from '../../../Utilities/api'
import { Project, Organization, ProjectStatus } from '../../../Utilities/types'
import moment from 'moment'
import { placeholderURL } from '../../../Utilities/etc'
import { useAsync } from 'react-use'
import * as api from '../../../Utilities/api'
import { Table } from 'antd'

interface Props {
  project?: Project
}

export const ProjectForm: React.FC<Props> = ({ project }: any) => {
  const form = useForm({ defaultValues: defaultValues() })
  const { register, handleSubmit, control, watch, setValue } = form
  const [disabled, setDisabled] = useState(!!project)
  const addToast = useToast()
  const [signUpDeadline, eventDate] = watch(['signUpDeadline', 'eventDate'])
  const minEnd = eventDate?.start || signUpDeadline || new Date()
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [waivers, setWaivers] = useState([{ title: '', link: '' }])
  const [status, updateStatus] = useState(project?.status)
  const [usersData, setUsersData] = useState<any>([])

  useEffect(() => {
    const fetchOrganizations = async() => {
      try {
        const org = await api.organizations.fetch(undefined, undefined)
        setOrganizations(org)
      } catch (error) {
        addToast(error as Error)
      }
    }

    const fetchAttendeesData = async() => {
      const attendeesPromises = project.attendees.map(async(attendee: any) => {
        const userId = attendee.userId

        const userDoc = await users.doc(userId).get()
        const userData = userDoc.data()

        return { ...attendee, ...userData }
      })

      const fetchedData = await Promise.all(attendeesPromises)
      setUsersData(fetchedData)
    }

    fetchOrganizations()
    fetchAttendeesData()
  }, [])

  useEffect(() => {
    if (project) {
      setWaivers(project.waivers || [])
    }
  }, [])

  const handleOrganizationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOrgID = event.target.value
    const selectedOrg: any = organizations.find(org => org.id === selectedOrgID)
    if (selectedOrg) {
      setValue('organizer.organizationID', selectedOrg.id)
      setValue('organizer.organization', selectedOrg.name)
      setValue('organizer.email', selectedOrg.email)
    }
  }

  const handleCauseAreaChange = (event: any) => {
    setValue('causeArea', event)
  }

  const addWaiver = () => {
    setWaivers([...waivers, { title: '', link: '' }])
  }

  const handleWaiverChange = (index: number, field: string, value: string) => {
    const updatedWaivers = [...waivers]
    updatedWaivers[index] = {
      ...updatedWaivers[index],
      [field]: value
    }
    setWaivers(updatedWaivers)
  }

  const changeStatus = async() => {
    if (!project?.id) addToast('This project doesn`t exist.')
    if (project?.status === ProjectStatus.completed) addToast('This project has expired and you cannot make any further changes to it')
    if (new Date() > project?.signUpDeadline) addToast('The sign-up deadline has already passed. Please change it and try again')
    const newStatus = await api.projects.project.setStatus(project.id, status === ProjectStatus.draft ? ProjectStatus.live : ProjectStatus.draft)
    updateStatus(newStatus)
  }

  function calculateAge(birthdate: any) {
    const today = new Date()
    const birthDate = new Date(birthdate)
    let age = today.getFullYear() - birthDate.getFullYear()
    const monthDifference = today.getMonth() - birthDate.getMonth()

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }

    return age
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Sign-up Date',
      dataIndex: 'signupDate',
      key: 'signupDate',
      render: (d: any) => {
        return <div>{d.toLocaleDateString()}</div>
      }
    },
    {
      title: 'Age',
      dataIndex: 'birthdate',
      key: 'birthdate',
      render: (d: any) => {
        return <div>{calculateAge(d)}</div>
      }
    }
  ]

  console.log(usersData)

  return (
    <div className={styles.container}>
      <FormProvider {...form}>
        <aside>
          <ImageUploader
            name='projectCover'
            control={control}
            baseStorageRef={storage.child('project-covers')}
            placeholder={placeholderURL(
              'Upload an image for this project',
              348,
              261
            )}
          />
        </aside>
        <form onSubmit={handleSubmit(submit)} id='project-form'>
          <fieldset>
            <header>
              <h1>
                <input
                  {...register('name', { required: true })}
                  placeholder='Project Name'
                  aria-label='Project Name'
                />
              </h1>
            </header>
            <section>
              <div className='right-button-container'>
                {status !== ProjectStatus.completed && (
                  <text onClick={changeStatus} className='medium-button'>
                    {status === ProjectStatus.live ? 'Unpublish' : 'Publish'}
                  </text>
                )}
              </div>
              <h3 className='medium-black-text'>Host</h3>
              <hr className='custom-hr' />
              <label className='h5-label horizontal'>
                Host Organization
                <select
                  value={form.watch('organizer.organizationID')}
                  {...register('organizer.organizationID')}
                  onChange={handleOrganizationChange}
                >
                  <option value=''>Select Organization</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </select>
              </label>
              <h3 className='medium-black-text'>Key Dates</h3>
              <hr className='custom-hr' />
              <label className='h5-label horizontal'>
                Sign-up Deadline
                <DatePicker
                  name='signUpDeadline'
                  placeholder='Sign-up Deadline'
                  minDate={new Date()}
                  required
                  control={control}
                />
              </label>
              <br />
              <label className='h5-label horizontal'>
                Event Start
                <DatePicker
                  name='eventDate.start'
                  placeholder='Event Start'
                  minDate={signUpDeadline || new Date()}
                  showTimeSelect
                  required
                  control={control}
                />
              </label>
              <br />
              <label className='h5-label horizontal'>
                Event End
                <DatePicker
                  name='eventDate.end'
                  placeholder='Event End'
                  minDate={minEnd}
                  maxDate={minEnd}
                  minTime={minEnd}
                  maxTime={moment(minEnd).endOf('day').toDate()}
                  showTimeSelect
                  required
                  control={control}
                />
              </label>

              <h3 className='medium-black-text'>Waivers</h3>
              <hr className='custom-hr' />
              {waivers.map((waiver, index) => (
                <div key={index} className={styles.waiver}>
                  <label className='h5-label horizontal'>
                    Waiver Title
                    <input
                      type='text'
                      value={waiver.title}
                      onChange={(e) =>
                        handleWaiverChange(index, 'title', e.target.value)
                      }
                      placeholder='Waiver Title'
                      aria-label='Waiver Title'
                    />
                  </label>
                  <br />
                  <label className='h5-label horizontal'>
                    Waiver Link
                    <input
                      type='text'
                      value={waiver.link}
                      onChange={(e) =>
                        handleWaiverChange(index, 'link', e.target.value)
                      }
                      placeholder='Waiver Link'
                      aria-label='Waiver Link'
                    />
                  </label>
                  <br />
                  <hr className='custom-hr' />
                </div>
              ))}
              <text className='small-button' onClick={addWaiver}>
                + Add Waiver
              </text>
              <br />
              <br />

              <div>
                <h3 className='medium-black-text'>Volunteers</h3>
                <hr className='custom-hr' />
                <div className={styles.volunteer}>
                  <label htmlFor=''>
                    <p className='small-text'>VOLUNTEERS NEEDED</p>
                    <input
                      {...register('desiredAttendees')}
                      type='number'
                      placeholder='Volunteers Desired'
                      aria-label='Volunteers Desired'
                    />
                  </label>
                  <label>
                    <p className='small-text'>MINIMUM AGE</p>
                    <input
                      {...register('volunteerRequirements.minimumAge')}
                      type='number'
                      placeholder='Minimum Age'
                      aria-label='Minimum Age'
                    />
                  </label>
                </div>
              </div>

              <h3 className='medium-black-text'>Location</h3>
              <hr className='custom-hr' />
              <AddressInput name='address' className={styles.address} />
              <h3 className='medium-black-text'>Event Details</h3>
              <label className=''>
                <p className='small-text'>Cause Area</p>
                <SDGSelect
                  value={form.watch('causeArea')}
                  name='causeArea'
                  required
                  label={''}
                  placeholder={'Cause Area'}
                  handleCauseAreaChange={handleCauseAreaChange}
                />
              </label>
              <label className='h5-label'>
                Duties and Expectations
                <textarea
                  {...register('dutiesAndExpectations', { required: true })}
                  placeholder='What will the volunteers be doing and what is expected of them?'
                />
              </label>
              <input
                type='submit'
                value={
                  disabled
                    ? 'Save Changes'
                    : project
                      ? 'Save Changes'
                      : 'Create Project'
                }
                form='project-form'
              />
            </section>
          </fieldset>
        </form>
      </FormProvider>
      {project && <div className='volunteer-signups'>
        <h3>Volunteer Sign-ups</h3>
        <hr />
        <Table columns={columns} dataSource={usersData} pagination={false}/>
      </div>}
    </div>
  )

  function defaultValues(): Partial<Project> {
    return {
      ...project,
      organizer: {
        name: project?.organizer.name,
        email: project?.organizer.email || '',
        organizationID: project?.organizer.organizationID,
        organization: project?.organizer.organization,
        organizationAbout: project?.organizer.organizationAbout
      }
    }
  }

  async function submit(data: any) {
    try {
      setDisabled(true)
      const projectData = {
        ...data,
        waivers
      }
      if (project) {
        await projects.project.update(projectData)
        addToast('Project Successfully Updated')
      } else {
        await projects.project.create(projectData)
        addToast('Project Successfully Added')
      }
    } catch (error) {
      addToast(error as Error)
    } finally {
      setDisabled(false)
    }
  }
}

const CreateProject: React.FC<{ orgID?: string }> = ({ orgID }) => {
  const async = useAsync(async() => {
    if (orgID) {
      return (await api.organizations.org.fetch(orgID)) as Organization
    }
  })
  if (async.loading) {
    return <div>Loading</div>
  }
  if (async.error) {
    return <div>{async.error.message}</div>
  }
  if (async.value || !orgID) {
    return <ProjectForm />
  }
  return <div>No organization for id: {orgID} found</div>
}

export default CreateProject
