import React from 'react'
import { classNames, colorForCauseArea, copyForCauseArea } from '../Utilities/etc'
import { CauseAreas } from '../Utilities/types'
import styles from './CauseAreaCell.module.scss'

interface CauseAreaCellProps {
  causeArea: CauseAreas
  className?: string
}

const CauseAreaCell: React.FC<CauseAreaCellProps> = ({ causeArea, className }) => {
  const backgroundColor = colorForCauseArea(causeArea)?.darkTheme

  return (
    <div
      className={classNames(styles.sdg, className)}
      style={{ backgroundColor }}
    >
      {copyForCauseArea(causeArea)}
    </div>
  )
}

export default CauseAreaCell
