import React from 'react'
import { Link } from 'react-router-dom'
import { Route } from '../../Utilities/Route'

const FourOhFour: React.FC = () => <>
  <h1>404</h1>
  <p>There is no content here.</p>
  <p><Link to={Route.home}>Go Home</Link>.</p>
</>

export default FourOhFour
