import React, { CSSProperties } from 'react'
import styles from './Select.module.scss'
import { classNames } from '../../Utilities/etc'
import { Select as AntdSelect } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form'

interface OptionType {
  value: string
  label: string
}

export interface Props<T extends FieldValues, P extends Path<T> = Path<T>> extends SelectProps<string> {
  name: P
  control?: Control<T>
  label: string
  options: OptionType[]
  defaultValue?: PathValue<T, P> | undefined
  labelClassName?: string
  labelStyle?: CSSProperties
}

const Select = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  labelClassName,
  labelStyle,
  defaultValue,
  ...props
}: Props<T>) => {
  const { Option } = AntdSelect

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <label className={classNames('h5-label', labelClassName)} style={labelStyle}>
          {label}
          <AntdSelect className={classNames(styles.select, props.className)} {...field} {...props}>
            {options.map((o) => (
              <Option key={o.value} value={o.value}>
                {o.label}
              </Option>
            ))}
          </AntdSelect>
        </label>
      )}
    />
  )
}

export default Select
