import { useLoadScript, Libraries } from '@react-google-maps/api'

const libraries: Libraries = ['places']

export default function useGooglePlaces() {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

  return useLoadScript({
    googleMapsApiKey,
    libraries,
    region: 'US'
  })
}
