import React, { useEffect, useState } from 'react'
import TicketItem from '../../Components/TicketItem'
import { useAsync } from 'react-use'
import * as api from '../../Utilities/api'
import { Spin } from 'antd'
import { Ticket } from '../../Utilities/types'

const Tickets: React.FC = () => {
  const { loading, error, value: initialTickets } = useAsync(api.tickets.fetchAll, [])
  const [tickets, setTickets] = useState<Ticket[]>([])
  const [modalVisibility, setModalVisibility] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    if (initialTickets) {
      setTickets(initialTickets)
      setModalVisibility(initialTickets.reduce((acc: any, ticket: any) => ({ ...acc, [ticket.id]: false }), {}))
    }
  }, [initialTickets])

  const resolveTicket = async(userId: string, ticketId: string, comment: string) => {
    try {
      await api.tickets.ticket.resolve(userId, ticketId, comment)
      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket.id === ticketId ? { ...ticket, status: 'resolved', comment } : ticket
        )
      )
      setModalVisibility(prevVisibility => ({ ...prevVisibility, [ticketId]: false }))
    } catch (error) {
      console.error('Failed to resolve ticket', error)
    }
  }

  const toggleModalVisibility = (ticketId: string) => {
    setModalVisibility(prevVisibility => ({
      ...prevVisibility,
      [ticketId]: !prevVisibility[ticketId]
    }))
  }

  if (loading) return <Spin size='large' className='centered' />
  if (error) return <p>{error.message}</p>

  return (
    <>
      <header>
        <h1 className='page-title-text'>Tickets</h1>
      </header>
      {tickets.map(ticket => (
        <TicketItem
          ticket={ticket}
          key={ticket.id}
          isModalVisible={modalVisibility[ticket.id] || false}
          onResolve={(comment: any) => resolveTicket(ticket.userid, ticket.id, comment)}
          onModalToggle={() => toggleModalVisibility(ticket.id)}
        />
      ))}
    </>
  )
}

export default Tickets
