import { useToasts, AppearanceTypes, Options } from 'react-toast-notifications'
import { messageForError } from '../../Utilities/etc'

type ToastOptions = Options & {
  appearance?: AppearanceTypes
};

export const useToast = () => {
  const { addToast } = useToasts()

  return (
    error: Error | string,
    options?: AppearanceTypes | ToastOptions | null,
    callback?: (id: string) => void
  ) => {
    let toastOptions: ToastOptions

    if (!options) {
      toastOptions = { appearance: error instanceof Error ? 'error' : 'info' }
    } else if (typeof options === 'string') {
      toastOptions = { appearance: options }
    } else {
      toastOptions = options
    }

    addToast(messageForError(error), toastOptions, callback)
  }
}

export default useToast
