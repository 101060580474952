import React, { useState, useEffect } from 'react'
import { authentication as auth } from './Utilities/api'
import { useNavigate, useLocation } from 'react-router-dom'
import { Route } from './Utilities/Route'
import useToast from './Utilities/Hooks/useToast'

const Footer: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const addToast = useToast()
  const [isHidden, hideFooter] = useState(false)

  useEffect(() => {
    hideFooter(!auth.currentUser())
  }, [location.pathname])

  return isHidden
    ? null
    : (
      <footer>
        <div className='main-size'>
          <button onClick={onClick}>Sign-out</button>
        </div>
      </footer>
    )

  async function onClick() {
    try {
      await auth.signOut()
      navigate(Route.home)
    } catch (error) {
      addToast(error as Error)
    }
  }
}

export default Footer
