import { ToastProvider } from 'react-toast-notifications'
import React from 'react'

interface ErrorBoundaryProps {
  children: React.ReactNode
}

interface ErrorBoundaryState {
  error: Error | null
  errorInfo: React.ErrorInfo | null
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null,
    errorInfo: null
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.error) {
      return (
        <ToastProvider>
          <div>
            <h2>Something went wrong.</h2>
            <p>{this.state.error.message}</p>
          </div>
        </ToastProvider>
      )
    }

    return <ToastProvider>{this.props.children}</ToastProvider>
  }
}

export default ErrorBoundary
