import React, { ReactElement } from 'react'
import { flatten, startCase, compact } from 'lodash'
import { Address, CauseAreas, Name } from './types'
import { $enum } from 'ts-enum-util'
import moment from 'moment'

export const classNames = (...objs: any) => flatten(objs).filter(Boolean).join(' ')

export function onlyTruthy(obj: Record<string, unknown>) {
  Object.entries(obj).forEach(([key, val]) =>
    (val && typeof val === 'object')
      ? onlyTruthy(val as Record<string, unknown>)
      : (!val && val !== false) && delete obj[key]
  )
  return obj
}

export const now = () => moment().toISOString()
export const isoString = (date: Date) => moment(date).toISOString()
export const dateFromISO = (date: any) => moment(date).toDate()

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function messageForError(obj: any): ReactElement {
  if (obj instanceof TypeError && obj.message === 'Failed to fetch') {
    return <p>Your Internet connection appears to be offline.</p>
  }

  // eslint-disable-next-line no-mixed-operators
  let msg = (
    obj.reason ||
    (obj.json && (obj.json.reason || obj.json.message)) ||
    obj.message ||
    `${obj}`
  )

  if (!msg.endsWith('.')) msg += '.'
  return <p
    style={{ whiteSpace: 'pre-wrap' }}
    dangerouslySetInnerHTML={{ __html: msg }} />
}

export function placeholderURL(copy: string, width: number, height: number) {
  return `https://via.placeholder.com/${width}x${height}/E8E8E8.jpeg?text=${copy.split(' ').join('+')}`
}

export function longNameForAddressComponent(type: string, address?: google.maps.GeocoderAddressComponent[]) {
  return address?.find(c => c.types.includes(type))?.long_name
}

export function copyForCauseArea(ca: CauseAreas) {
  switch (ca) {
  case 'noPoverty':
  case 'zeroHunger':
  case 'qualityEducation':
  case 'genderEquality':
  case 'cleanWater':
  case 'economicGrowth':
  case 'climateAction':
  case 'lifeBelowWater':
  case 'lifeOnLand':
    return startCase(ca)
  case 'goodHealth':
    return 'Good Health & Wellbeing'
  case 'cleanEnergy':
    return 'Affordable & Clean Energy'
  case 'innovation':
    return 'Innovation & Infrastructure'
  case 'inequalities':
    return 'Reduce Inequalities'
  case 'communities':
    return 'Sustainable Communities'
  case 'consumption':
    return 'Responsible Consumption'
  case 'justice':
    return 'Peace and Justice'
  case 'partnerships':
    return 'Partnerships for the Goals'
  }
}

export function colorForCauseArea(ca: CauseAreas) {
  switch (ca) {
  case 'noPoverty':
    return { lightTheme: '#FF001A', darkTheme: '#FF3348' }
  case 'zeroHunger':
    return { lightTheme: '#DBA100', darkTheme: '#DBAD2C' }
  case 'goodHealth':
    return { lightTheme: '#009E3D', darkTheme: '#209E50' }
  case 'qualityEducation':
    return { lightTheme: '#D50029', darkTheme: '#D52B4B' }
  case 'genderEquality':
    return { lightTheme: '#FF2707', darkTheme: '#FF4D33' }
  case 'cleanWater':
    return { lightTheme: '#00B0E0', darkTheme: '#2DBAE0' }
  case 'cleanEnergy':
    return { lightTheme: '#FFB300', darkTheme: '#EFB033' }
  case 'economicGrowth':
    return { lightTheme: '#9B0136', darkTheme: '#9B1F4A' }
  case 'innovation':
    return { lightTheme: '#FF6300', darkTheme: '#FF8233' }
  case 'inequalities':
    return { lightTheme: '#F80A88', darkTheme: '#F8329B' }
  case 'communities':
    return { lightTheme: '#FF9A00', darkTheme: '#E8963D' }
  case 'consumption':
    return { lightTheme: '#DA8B00', darkTheme: '#C48A37' }
  case 'climateAction':
    return { lightTheme: '#397734', darkTheme: '#397734' }
  case 'lifeBelowWater':
    return { lightTheme: '#007EC4', darkTheme: '#278CC4' }
  case 'lifeOnLand':
    return { lightTheme: '#00B636', darkTheme: '#24B650' }
  case 'justice':
    return { lightTheme: '#00568E', darkTheme: '#1C618E' }
  case 'partnerships':
    return { lightTheme: '#0F336D', darkTheme: '#16376D' }
  }
}

export function descForCauseArea(ca: CauseAreas) {
  const key = $enum(CauseAreas).getKeyOrThrow(ca)
  switch (key) {
  case 'noPoverty':
    return 'End poverty in all its forms everywhere'
  case 'zeroHunger':
    return 'End hunger, achieve food security and improved nutrition and promote sustainable agriculture'
  case 'goodHealth':
    return 'Ensure healthy lives and promote well-being for all at all ages'
  case 'qualityEducation':
    return 'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all'
  case 'genderEquality':
    return 'Achieve gender equality and empower all women and girls'
  case 'cleanWater':
    return 'Ensure availability and sustainable management of water and sanitation for all'
  case 'cleanEnergy':
    return 'Ensure access to affordable, reliable, sustainable and modern energy for all'
  case 'economicGrowth':
    return 'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all'
  case 'innovation':
    return 'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation'
  case 'inequalities':
    return 'Reduce inequality within and among countries'
  case 'communities':
    return 'Make cities and human settlements inclusive, safe, resilient and sustainable'
  case 'consumption':
    return 'Ensure sustainable consumption and production patterns'
  case 'climateAction':
    return 'Take urgent action to combat climate change and its impacts'
  case 'lifeBelowWater':
    return 'Conserve and sustainably use the oceans, seas and marine resources for sustainable development'
  case 'lifeOnLand':
    return 'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss'
  case 'justice':
    return 'Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels'
  case 'partnerships':
    return 'Strengthen the means of implementation and revitalize the global partnership for sustainable development'
  }
}

const states = [
  ['Arizona', 'AZ'],
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY']
]

export function stateFullFormToAbbr(state?: string) {
  if (!state) { return }
  const s = states.find(s => s[0].toUpperCase() === state.toUpperCase())
  return s ? s[1] : state
}

export function stateAbbrToFullForm(state?: string) {
  if (!state) { return }
  const s = states.find(s => s[1].toUpperCase() === state.toUpperCase())
  return s ? s[0] : state
}

export function signedUpVolunteers(signedUp: number, desired = 0) {
  if (!desired) return signedUp.toString()
  return signedUp > desired
    ? `${desired}/${desired}`
    : `${signedUp}/${desired}`
}

export function waitlistedVolunteers(signedUp: number, desired = 0) {
  if (!desired || signedUp < desired) return 0
  return signedUp - desired
}

export function fullName(name: Name) {
  return `${name.first || ''} ${name.last || ''}`.trim()
}

export function age(birthdate?: Date) {
  return birthdate && moment().diff(moment(birthdate), 'year')
}

export function prettyDate(date?: Date) {
  return date && moment(date).format('LL')
}

export function fullAddress(address: Address, oneLine = false) {
  const { addressLine1, addressLine2, city, state, postalCode, country } = address
  const separator = oneLine ? ', ' : '\n'
  const parts = compact([addressLine1, addressLine2, `${city}${state ? `, ${state}` : ''} ${postalCode}`, country])
  return parts.join(separator)
}

export const composeAddress = (location: Address): string =>
  `${location.addressLine1
    ? `${location.addressLine1} `
    : ''}${location.addressLine2
    ? `${location.addressLine2} `
    : ''}${location.city
    ? `${location.city} `
    : ''}${location.state
    ? `${location.state} `
    : ''}${location.postalCode
    ? `${location.postalCode} `
    : ''}${location.country
    ? `${location.country}`
    : ''}`
