import React from 'react'
import styles from './UserItem.module.scss'
import { User } from '../Utilities/types'
import { age, fullName } from '../Utilities/etc'
import { Link } from 'react-router-dom'
import { Route } from '../Utilities/Route'

const UserItem: React.FC<{user: User}> = ({ user }) => {
  return <div className={styles.container}>
    <div>
      <h5>Name</h5>
      { user.name ? fullName(user.name) : '--' }
    </div>
    <div>
      <h5>Email</h5>
      { user.email || '--' }
    </div>
    <div>
      <h5>Age</h5>
      { age(user.birthdate) || '--' }
    </div>
    <Link to={`${Route.users}/${user.id}`}>See Profile</Link>
  </div>
}

export default UserItem
