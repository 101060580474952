import React from 'react'
import { useAsync } from 'react-use'
import * as api from '../../../Utilities/api'
import * as types from '../../../Utilities/types'
import { ProjectForm } from '../create'
import { useParams } from 'react-router-dom'

const Project: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const async = useAsync(async() => await api.projects.project.fetch(id) as types.Project)

  if (async.loading) {
    return <p>Loading...</p>
  }
  if (async.error) {
    return <p>{async.error.message}</p>
  }
  return <ProjectForm project={async.value} />
}

export default Project
