import React from 'react'
import { useAsync } from 'react-use'
import * as api from '../../../Utilities/api'
import { CauseAreas, Project } from '../../../Utilities/types'
import styles from './index.module.scss'
import { fullName, prettyDate } from '../../../Utilities/etc'
import { startCase } from 'lodash'
import ProjectItem from '../../../Components/ProjectItem'
import CauseAreaCell from '../../../Components/CauseAreaCell'
import { Spin } from 'antd'
import { useParams } from 'react-router-dom'

const UserProfile = () => {
  const { id }: any = useParams<{ id: string }>()
  const async = useAsync(async() => {
    const user = await api.users.user.fetch(id)
    const projects = await api.projects.fetch([{ fieldPath: 'attendeesIds', opStr: 'array-contains', value: id }], undefined) as Project[]
    // const roleRsp = await api.users.user.role(id)
    return { user, projects }
  }, [id])

  if (async.loading) return <Spin size='large' className='centered' />
  if (async.error) return <p>{async.error.message}</p>

  const { user, projects } = async.value!

  return (
    <div className={styles.container}>
      <div>
        <h1 className='page-title-text'>{user.name ? fullName(user.name) : 'Anonymous'}</h1>
        {/* <span>{'startCase(roleRsp)'}</span> */}
        <div className={styles.userDetailsContainer}>
          {user.email && (
            <div>
              <h5 className='small-text'>Email</h5>
              <p>{user.email}</p>
            </div>
          )}
          {user.birthdate && (
            <div>
              <h5 className='small-text'>Birth Date</h5>
              <p>{prettyDate(user.birthdate)}</p>
            </div>
          )}
          {user.gender && (
            <div>
              <h5 className='small-text'>Gender</h5>
              <p>{startCase(user.gender)}</p>
            </div>
          )}
        </div>
      </div>
      {user.causeAreas && <FavoritedCauseAreas favorites={user.causeAreas} />}
      {projects.length > 0 && (
        <>
          <h5 className='small-text' style={{ marginTop: '2rem' }}>Projects</h5>
          {projects.sort((a, b) => (a < b ? 1 : -1)).map(p => (
            <ProjectItem project={p} key={p.id} />
          ))}
        </>
      )}
    </div>
  )
}

interface FavoritedCauseAreasProps {
  favorites: CauseAreas[]
}

const FavoritedCauseAreas: React.FC<FavoritedCauseAreasProps> = ({ favorites }) => {
  return (
    <>
      <h5 className='small-text'>Favorited SDGs</h5>
      <div className={styles.sdgContainer}>
        {favorites.map(ca => (
          <CauseAreaCell causeArea={ca} key={ca} />
        ))}
      </div>
    </>
  )
}

export default UserProfile
