import React from 'react'
import { classNames } from '../../Utilities/etc'
import styles from './CreateItemButton.module.scss'
import { Link, LinkProps, To } from 'react-router-dom'

type Props = React.PropsWithoutRef<LinkProps> & React.RefAttributes<HTMLAnchorElement> & {
  to: To
};

const CreateItemButton: React.FC<Props> = (props) => (
  <Link {...props} className={classNames(styles.createItemButton, props.className)}>
    <span className="material-icons">add</span>
    {props.children}
  </Link>
)

export default CreateItemButton
