/* eslint-disable camelcase */

import { startCase } from 'lodash'

enum Route {
  projects_create = '/projects/create',
  projects = '/projects',
  organizations_create = '/organizations/create',
  organizations = '/organizations',
  organization_projects_create = '/organizations/:id/projects/create',
  users = '/users',
  newsletter = '/newsletter',
  quotes_create = '/quotes/create',
  quotes = '/quotes',
  stats = '/stats',
  tickets = '/tickets',
  home = '/',
}

function getTitle(route: Route): string {
  return startCase(route)
}

export { Route, getTitle }
