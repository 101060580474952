import React from 'react'
import styles from './ProjectItem.module.scss'
import { Project } from '../Utilities/types'
import LazyLoad from 'react-lazyload'
import moment from 'moment'
import { stateFullFormToAbbr, signedUpVolunteers, waitlistedVolunteers } from '../Utilities/etc'
import { useNavigate } from 'react-router-dom'
import { Route } from '../Utilities/Route'
import useStorageImage from '../Utilities/Hooks/useStorageImage'

const ProjectItem: React.FC<{ project: Project }> = ({ project }) => {
  const src = useStorageImage(project.projectCover)
  const navigate = useNavigate()
  const hasCityAndState = !!project.address?.city && !!project.address?.state
  const hostCopy = hasCityAndState
    ? `Hosted by ${project.organizer.name || project.organizer.organization} in ${project.address?.city}, ${stateFullFormToAbbr(project.address?.state)}`
    : `Hosted virtually by ${project.organizer.name || project.organizer.organization}`

  return (
    <LazyLoad offset={100} key={project.id} placeholder={<div>placeholder</div>}>
      <div className={styles.container} onClick={() => navigate(`${Route.projects}/${project.id}`)}>
        <LazyLoad placeholder={<div style={{ height: 165, width: 220, backgroundColor: 'light-gray' }} />}>
          <img src={src?.value} alt={`${project.name}'s cover`} />
        </LazyLoad>
        <div className={styles.dates}>
          <div>
            <h5 className='small-text'>Sign-up deadline</h5>
            <div>{moment(project.signUpDeadline).format('ll')}</div>
          </div>
          <div>
            <h5 className='small-text'>Event Date</h5>
            <div>{moment(project.eventDate.start).format('ll')}</div>
          </div>
        </div>
        {project?.attendees && (<div className={styles.volunteers}>
          <div>
            <h5 className='small-text'>Signed Up</h5>
            <div>{signedUpVolunteers(project.attendees.length, project.desiredAttendees)}</div>
          </div>
          {project.desiredAttendees && (
            <div>
              <h5 className='small-text'>Waitlisted</h5>
              <div>{waitlistedVolunteers(project.attendees.length, project.desiredAttendees)}</div>
            </div>
          )}
        </div>)}
        <div className={styles.details}>
          <h4 className='bold-black-text'>{project.name}</h4>
          <div className={styles.hosted}>{hostCopy}</div>
          <h5 className='small-text'>Volunteer Duties + Expectations</h5>
          <p>{project.dutiesAndExpectations}</p>
        </div>
      </div>
    </LazyLoad>
  )
}

export default ProjectItem
