import React from 'react'
import { useForm } from 'react-hook-form'
import styles from './index.module.scss'
import { authentication as auth } from '../Utilities/api'
import { useNavigate } from 'react-router-dom'
import { Route } from '../Utilities/Route'
import useToast from '../Utilities/Hooks/useToast'

interface FormData {
  email: string
  password: string
}

const Landing: React.FC = () => {
  const { register, handleSubmit } = useForm<FormData>()
  const navigate = useNavigate()
  const addToast = useToast()

  // Check if user is already authenticated
  React.useEffect(() => {
    if (auth.currentUser()) {
      navigate(Route.projects)
    }
  }, [navigate])

  async function submit(data: FormData) {
    try {
      const { email, password } = data
      await auth.signIn(email, password)
      navigate(Route.projects)
    } catch (error) {
      console.error('Error during sign-in:', error)
      addToast(error as Error)
    }
  }

  return (
    <>
      <header>
        <h1 className='page-title-text'>Welcome</h1>
      </header>

      <form onSubmit={handleSubmit(submit)} className={styles.form}>
        <fieldset>
          <label>
            Email
            <input
              {...register('email', { required: true })}
              type='email'
              autoComplete='email'
            />
          </label>

          <label>
            Password
            <input
              {...register('password', { required: true })}
              type='password'
              autoComplete='current-password'
            />
          </label>

          <input type='submit' value='Submit'/>
        </fieldset>

      </form>
    </>
  )
}

export default Landing
