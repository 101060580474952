import React from 'react'
import { useAsync } from 'react-use'
import * as api from '../../../Utilities/api'
import * as types from '../../../Utilities/types'
import { OrganizationForm } from '../../../Routes/organizations/create'
import { useParams } from 'react-router-dom'

const Organization: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const async = useAsync(async() => {
    if (id) {
      return await api.organizations.org.fetch(id) as types.Organization
    }
    throw new Error('Organization ID is undefined')
  })

  if (async.loading) {
    return <p>Loading...</p>
  }
  if (async.error) {
    return <p>{async.error.message}</p>
  }
  return <OrganizationForm org={async.value} />
}

export default Organization
