import React, { useState } from 'react'
import styles from './index.module.scss'
import CreateItemButton from '../../Components/Primitives/CreateItemButton'
import { Route } from '../../Utilities/Route'
import { useAsyncRetry, useLocalStorage } from 'react-use'
import * as api from '../../Utilities/api'
import { Project, ProjectStatus, CauseAreas } from '../../Utilities/types'
import ProjectItem from '../../Components/ProjectItem'
import { Link } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import useFullMainArea from '../../Utilities/Hooks/useFullMainArea'
import { stateFullFormToAbbr, stateAbbrToFullForm, classNames, longNameForAddressComponent } from '../../Utilities/etc'
import Select from '../../Components/Primitives/Select'
import { $enum } from 'ts-enum-util'
import { capitalize, compact } from 'lodash'
import { Autocomplete } from '@react-google-maps/api'
import { Spin } from 'antd'

// Import Where and Order interfaces
import { Where, Order } from '../../Utilities/api'

// Define form values interface
interface FormValues {
  location: string | undefined
  sort: string
  sdg?: string
  status?: string
}

const Projects: React.FC = () => {
  const [defaultLocation, setDefaultLocation] = useLocalStorage('projectsLastLocation', 'Savannah, GA')
  const [where, setWhere] = useState<Where[]>(locationWhere(defaultLocation)!)
  const [order, setOrder] = useState<Order>({ fieldPath: 'signUpDeadline', directionStr: 'desc' })
  const async = useAsyncRetry(async() => await api.projects.fetch(where, order) as Project[])
  const methods = useForm<FormValues>({ defaultValues: { location: defaultLocation, sort: 'signUpDeadline' } })
  const { register, handleSubmit, control, setValue } = methods
  const [autoComplete, setAutoComplete] = useState<google.maps.places.Autocomplete>()
  useFullMainArea()

  const sortOptions = [
    { value: 'signUpDeadline', label: 'Sign-up Deadline' }
  ]
  const statusOptions = $enum(ProjectStatus).getKeys().map((s) => ({ value: s, label: capitalize(s) }))

  const sdgOptions = $enum(CauseAreas).getKeys().map(key => ({ value: key, key: key, label: capitalize(key) }))

  return (
    <>
      <header className="">
        <h1 className='page-title-text'>Projects</h1>
        <CreateItemButton to={Route.projects_create} style={{ paddingRight: 'inherit' }}>
          Create Project
        </CreateItemButton>
      </header>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <div className={styles.search}>
            <div className="">{renderCitySearch()}</div>
            <input type="submit" value="Search" />
          </div>
          {/* TODO: remove the followign inline css */}
          <div className={classNames('', styles.additionalOptions)}>
            <Select<FormValues> name="sort" label="Sort" placeholder='Sort' options={sortOptions} control={control} style={{ display: 'flex' }} />
            <Select<FormValues> name="sdg" label="Cause Areas" placeholder="Filter by Cause Area" options={sdgOptions} control={control} style={{ display: 'flex' }} />
            <Select<FormValues> name="status" allowClear label="Status" placeholder="Filter by Project Status" options={statusOptions} control={control} style={{ display: 'flex' }} />
          </div>
        </form>
      </FormProvider>
      <div className="" style={{ marginBottom: '2rem' }}>
        {renderProjects()}
      </div>
    </>
  )

  function submit(data: FormValues) {
    const lWhere = locationWhere(data.location) || []
    const sdgWhere: Where | undefined = data.sdg ? { fieldPath: 'causeArea', opStr: '==', value: data.sdg } : undefined
    const statusWhere: Where | undefined = data.status ? { fieldPath: 'status', opStr: '==', value: data.status } : undefined

    setWhere(compact([...lWhere, sdgWhere, statusWhere]))
    setOrder({ fieldPath: data.sort, directionStr: 'desc' })
    async.retry()
  }

  function locationWhere(searchTerm?: string) {
    if (!searchTerm) return
    const regexpCityState = /([^,]+),\s(\w+)/
    const match = searchTerm.match(regexpCityState)

    let cityWhere: Where
    let stateWhere: Where | undefined

    if (match) {
      cityWhere = { fieldPath: 'address.city', opStr: '==', value: match[1] }
      stateWhere = { fieldPath: 'address.state', opStr: '==', value: stateAbbrToFullForm(match[2]) }
    } else {
      cityWhere = { fieldPath: 'address.city', opStr: '==', value: searchTerm }
    }

    return compact([cityWhere, stateWhere])
  }

  function renderProjects() {
    const { loading, error, value: projects } = async

    if (error) {
      return <p>{error.message}</p>
    }
    return (
      <Spin size="large" spinning={loading} style={{ margin: '4rem 0' }}>
        {loading
          ? (
            <></>
          )
          : !projects?.length
            ? (
              <p>
                No projects found, click <Link to={Route.projects_create}>here</Link> to create one
              </p>
            )
            : (
              projects?.map((project) => <ProjectItem project={project} key={project.id} />)
            )}
      </Spin>
    )
  }

  function setAutocompletedValues() {
    if (!autoComplete) {
      return
    }
    const { address_components: address } = autoComplete.getPlace()
    const city = longNameForAddressComponent('locality', address) || longNameForAddressComponent('administrative_area_level_3', address)
    const state = longNameForAddressComponent('administrative_area_level_1', address)
    const copy = `${city}, ${stateFullFormToAbbr(state)}`
    setValue('location', copy)
    setDefaultLocation(copy)
  }

  function renderCitySearch() {
    return (
      <Autocomplete
        onLoad={(ac) => {
          ac.setFields(['address_components'])
          ac.setTypes(['(cities)'])
          ac.setComponentRestrictions({ country: 'us' })
          setAutoComplete(ac)
        }}
        onPlaceChanged={setAutocompletedValues}
      >
        <input type="text" placeholder="Location..." {...register('location')} />
      </Autocomplete>
    )
  }
}

export default Projects
