import React from 'react'
import styles from './OrganizationItem.module.scss'
import { Organization } from '../Utilities/types'
import LazyLoad from 'react-lazyload'
import useStorageImage from '../Utilities/Hooks/useStorageImage'
import { placeholderURL } from '../Utilities/etc'
import { useNavigate } from 'react-router-dom'
import { Route } from '../Utilities/Route'

const OrganizationItem: React.FC<{ org: Organization }> = ({ org }) => {
  const src = useStorageImage(org.logo)
  const defaultSrc = placeholderURL('Logo Placeholder', 40, 40)
  const navigate = useNavigate()

  return (
    <LazyLoad offset={100} key={org.id} placeholder={<div>placeholder</div>}>
      <div
        className={styles.container}
        onClick={() => navigate(`${Route.organizations}/${org.id}`)}
      >
        <div className={styles.organizationImage}>
          <img
            src={src.value || defaultSrc}
            alt={src.value ? `${org.logo}'s Logo` : 'Default Logo'}
          />
        </div>

        <div className={styles.organizationCard}>
          <div>
            <h2>{org.name}</h2>
          </div>
          <div className={styles.organizationDetails}>
            <div>
              <h3>WEBSITE</h3>
              {org.website
                ? <a href={org.website} target="_blank" rel="noopener noreferrer"> { org.website } </a>
                : '--' }
            </div>

            <div>
              <h3>EMAIL</h3>
              {org.email ? <a href={`mailto:${org.email}`}>{org.email}</a> : '--'}
            </div>

            <div>
              <div>
                <h3>PHONE</h3>
                {org.phone ? <a href={`tel:${org.phone}`}>{org.phone}</a> : '--'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LazyLoad>
  )
}

export default OrganizationItem
