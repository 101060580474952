import React, { useState, useEffect } from 'react'
import styles from './ImageUploader.module.scss'
import { classNames } from '../Utilities/etc'
import useToast from '../Utilities/Hooks/useToast'
import { Controller, Control } from 'react-hook-form'
import { storage } from '../Utilities/firebase'
import firebase from 'firebase/compat/app'
import addImg from '../Assets/add-photo-camera-svgrepo-com (2).svg'
interface Props {
  containerClassName?: string
  baseStorageRef: firebase.storage.Reference
  onChange(ref: string): void
  imageProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
  value?: string
  control?: Control<any>
  name: string
  placeholder: string
}

const Uploader: React.FC<Props> = props => {
  const { imageProps, containerClassName, baseStorageRef, onChange, value, placeholder } = props
  const [image, setImage] = useState<File>()
  const [imageUrl, setImageUrl] = useState<string>(placeholder)
  const [disabled, setDisabled] = useState(false)
  const [progress, setProgress] = useState(0)
  const addToast = useToast()
  // const hasImage = imageUrl && imageUrl !== placeholder

  useEffect(() => {
    async function fetchImage(path: string) {
      try {
        const url = await storage.child(path).getDownloadURL()
        setImageUrl(url)
      } catch (error) {
        addToast(error as Error)
      }
    }
    if (value) { fetchImage(value) }
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    setDisabled(false)
    if (!image) { return }
    const ref = baseStorageRef.child(image.name)
    const task = ref.put(image)
    task.on('state_changed',
      function progress(snapshot) {
        setProgress(snapshot.bytesTransferred / snapshot.totalBytes * 100)
      },
      function error(err) {
        addToast(err)
      },
      function complete() {
        addToast('Image sucessfully added', 'success')
        onChange(ref.fullPath)
      }
    )
    return () => {
      task.cancel()
    }
    // eslint-disable-next-line
  }, [image])

  return <div className={classNames(styles.container, containerClassName)}>
    { <img {...imageProps} className={ styles.uploadedImg } src={imageUrl} alt={`Uploaded File ${image ? image.name : ''}`}/>}
    <div className={styles.progressBar}><div style={{ width: `${progress}%` }}/></div>
    <input
      type='file'
      accept='image/png, image/jpeg'
      id='upload-image'
      disabled={disabled}
      onChange={event => {
        const image = event?.target?.files?.length ? event.target.files[0] : undefined
        if (image) {
          setImageUrl(window.URL.createObjectURL(image))
          setImage(image)
        }
      }}
    />
    <label className={styles.uploadButton} htmlFor='upload-image'>
      <img src={addImg} className={ styles.addImg } alt="" />
    </label>
  </div>
}

const ImageUploader: React.FC<Omit<Props, 'onChange'>> = props => (
  <Controller
    {...props}
    render={({ field }) => (
      <Uploader
        {...field}
        {...props}
      />
    )}
  />
)

export default ImageUploader
