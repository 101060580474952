/* eslint-disable no-void */
'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.ContentCategory = exports.UserRole = exports.CauseAreas = exports.Gender = exports.ProjectStatus = void 0
let ProjectStatus;
(function(ProjectStatus) {
  ProjectStatus.draft = 'draft'
  ProjectStatus.live = 'live'
  ProjectStatus.completed = 'completed'
})(ProjectStatus || (exports.ProjectStatus = ProjectStatus = {}))
let Gender;
(function(Gender) {
  Gender.male = 'male'
  Gender.female = 'female'
  Gender.nonBinary = 'nonBinary'
  Gender.other = 'other'
  Gender.unspecified = 'unspecified'
})(Gender || (exports.Gender = Gender = {}))
let CauseAreas;
(function(CauseAreas) {
  CauseAreas.noPoverty = 'noPoverty'
  CauseAreas.zeroHunger = 'zeroHunger'
  CauseAreas.goodHealth = 'goodHealth'
  CauseAreas.qualityEducation = 'qualityEducation'
  CauseAreas.genderEquality = 'genderEquality'
  CauseAreas.cleanWater = 'cleanWater'
  CauseAreas.cleanEnergy = 'cleanEnergy'
  CauseAreas.economicGrowth = 'economicGrowth'
  CauseAreas.innovation = 'innovation'
  CauseAreas.inequalities = 'inequalities'
  CauseAreas.communities = 'communities'
  CauseAreas.consumption = 'consumption'
  CauseAreas.climateAction = 'climateAction'
  CauseAreas.lifeBelowWater = 'lifeBelowWater'
  CauseAreas.lifeOnLand = 'lifeOnLand'
  CauseAreas.justice = 'justice'
  CauseAreas.partnerships = 'partnerships'
})(CauseAreas || (exports.CauseAreas = CauseAreas = {}))
let UserRole;
(function(UserRole) {
  UserRole.admin = 'admin'
  UserRole.ivintManager = 'ivintManager'
  UserRole.ivintRep = 'ivintRep'
  UserRole.orgManager = 'orgManager'
  UserRole.orgRep = 'orgRep'
  UserRole.ambassador = 'ambassador'
  UserRole.volunteer = 'volunteer'
})(UserRole || (exports.UserRole = UserRole = {}))
let ContentCategory;
(function(ContentCategory) {
  ContentCategory.story = 'story'
  ContentCategory.project = 'project'
})(ContentCategory || (exports.ContentCategory = ContentCategory = {}))
