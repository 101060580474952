import React from 'react'
import { CauseAreas } from '../../Utilities/types'
import { $enum } from 'ts-enum-util'
import { Select } from 'antd'
import Styles from './SDGSelect.module.scss'

interface SDGSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string
  placeholder: string
  name: string
  handleCauseAreaChange: any
}

function getLabel(value: any) {
  const sentences: any = {
    noPoverty: 'No Poverty',
    zeroHunger: 'Zero Hunger',
    goodHealth: 'Good Health & Wellbeing',
    qualityEducation: 'Quality Education',
    genderEquality: 'Gender Equality',
    cleanWater: 'Clean Water',
    cleanEnergy: 'Affordable & Clean Energy',
    economicGrowth: 'Economic Growth',
    innovation: 'Innovation & Infrastructure',
    inequalities: 'Reduce Inequalities',
    communities: 'Sustainable Communities',
    consumption: 'Responsible Consumption',
    climateAction: 'Climate Action',
    lifeBelowWater: 'Life Below Water',
    lifeOnLand: 'Life On Land',
    justice: 'Peace and Justice',
    partnerships: 'Partnerships for the Goals'
  }

  return sentences[value] || 'Value not found.'
}

const SDGSelect: React.FC<SDGSelectProps> = ({ label, placeholder, handleCauseAreaChange, value }) => {
  const sdgOptions = $enum(CauseAreas).getKeys().map(key => ({ value: key, key: key, label: getLabel(key) }))
  return (
    <div className={Styles.causeArea}>
      <label>{label}</label>
      <Select value={value} onChange={handleCauseAreaChange} className={Styles.select} style={{ width: '100%', height: '60px !important' }} placeholder={placeholder} options={sdgOptions}></Select>
    </div>
  )
}

export default SDGSelect
