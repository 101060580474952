import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/analytics'
import 'firebase/compat/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyBQqqWLlNu6Agi28yGoY5ojCBwz_AViJmY',
  authDomain: 'ivolunteerint.firebaseapp.com',
  databaseURL: 'https://ivolunteerint.firebaseio.com',
  projectId: 'ivolunteerint',
  storageBucket: 'ivolunteerint.appspot.com',
  messagingSenderId: '221397825971',
  appId: '1:221397825971:web:18cd9b3337ba31ef40b3c6',
  measurementId: 'G-G-VKN8V11F4T'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const db = firebase.firestore()
export const auth = firebase.auth()
export const users = db.collection('users')
export const quotes = db.collection('quotes')
export const projects = db.collection('projects')
export const organizations = db.collection('organizations')
export const causeAreas = db.collection('causeAreas')
export const locationRequests = db.collection('locationRequests')
export const tickets = db.collection('tickets')
export const storage = firebase.storage().ref()
export const functions = firebase.functions()
