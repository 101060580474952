import React, { useState, useRef, useCallback } from 'react'
import { GoogleMap, Autocomplete, Marker } from '@react-google-maps/api'
import { useFormContext } from 'react-hook-form'
import styles from './AddressInput.module.scss'
import useGooglePlaces from '../../Utilities/Hooks/useGooglePlaces'

interface Props {
  name: string
  className?: string
  placeholder?: string
}

const mapContainerStyle = {
  width: '100%',
  height: '400px'
}

const center = {
  lat: 32.0809,
  lng: -81.0912
}

const AddressInput: React.FC<Props> = ({ name }) => {
  const { register, setValue } = useFormContext()
  const [autoComplete, setAutoComplete] = useState<google.maps.places.Autocomplete>()
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | null>(null)
  const { isLoaded, loadError } = useGooglePlaces()

  const mapRef = useRef<google.maps.Map | null>(null)

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map
    setMap(map)
  }, [])

  if (!isLoaded || loadError) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={center}
        onLoad={onMapLoad}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
      <Autocomplete
        onLoad={(ac) => {
          ac.setFields(['address_components', 'place_id', 'geometry'])
          ac.setTypes(['address'])
          setAutoComplete(ac)
          ac.setComponentRestrictions({ country: 'us' })
        }}
        onPlaceChanged={setAutocompletedValues}
      >
        <input
          placeholder={'Search for address'}
          className={styles.mapInput}
        />
      </Autocomplete>

      <div className={styles.inputGroup}>
        <label>
          <h4 className='small-text'>CONTEXTUAL</h4>
          <input {...register(`${name}.contextual`)} placeholder={'Contextual'} />
        </label>

        <label>
          <h4 className='small-text'>ADDRESS LINE 1</h4>
          <input {...register(`${name}.addressLine1`)} placeholder={'Address 1'} />
        </label>

        <label>
          <h4 className='small-text'>ADDRESS LINE 2</h4>
          <input {...register(`${name}.addressLine2`)} placeholder={'Address 2'} />
        </label>

        <label>
          <h4 className='small-text'>CITY</h4>
          <input disabled {...register(`${name}.city`)} placeholder={'City'} />
        </label>

        <label>
          <h4 className='small-text'>STATE</h4>
          <input disabled {...register(`${name}.state`)} placeholder={'State'} />
        </label>

        <label>
          <h4 className='small-text'>ZIP CODE</h4>
          <input disabled {...register(`${name}.postalCode`)} placeholder={'Zip Code'} />
        </label>

        <label>
          <h4 className='small-text'>COUNTRY</h4>
          <input disabled {...register(`${name}.country`)} placeholder={'Country'} />
        </label>

        <input disabled {...register(`${name}.lat`)} type='hidden' />
        <input disabled {...register(`${name}.lng`)} type='hidden' />
        <input disabled {...register(`${name}.placeID`)} type='hidden' />
      </div>
    </div>
  )

  function setAutocompletedValues() {
    if (!autoComplete) {
      return
    }
    const place = autoComplete.getPlace()
    const addressComponents = place.address_components || []
    const location = place.geometry?.location
    const lat = location?.lat()
    const lng = location?.lng()

    const getComponent = (type: string) => addressComponents.find(component => component.types.includes(type))?.long_name || ''

    const addressLine1 = `${getComponent('street_number')} ${getComponent('route')}`.trim()
    const addressLine2 = getComponent('sublocality_level_1')
    const city = getComponent('locality') || getComponent('administrative_area_level_2')
    const state = getComponent('administrative_area_level_1')
    const postalCode = getComponent('postal_code')
    const country = getComponent('country')

    setValue(`${name}.addressLine1`, addressLine1)
    setValue(`${name}.addressLine2`, addressLine2)
    setValue(`${name}.city`, city)
    setValue(`${name}.state`, state)
    setValue(`${name}.postalCode`, postalCode)
    setValue(`${name}.country`, country)
    setValue(`${name}.placeID`, place.place_id)
    setValue(`${name}.lat`, lat?.toString())
    setValue(`${name}.lng`, lng?.toString())

    if (lat !== undefined && lng !== undefined) {
      setMarkerPosition({ lat, lng })
      map?.panTo({ lat, lng })
    }
  }
}

export default AddressInput
