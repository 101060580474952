import { storage } from '../../Utilities/firebase'
import { useAsync } from 'react-use'

export default function useStorageImage(path?: string) {
  return useAsync(async() => {
    if (!path) return

    try {
      const url = await storage.child(path).getDownloadURL()
      return url as string
    } catch (error) {
      console.error('Failed to fetch image URL:', error)
      throw error
    }
  }, [path])
}
