import React, { useState } from 'react'
import styles from './TicketItem.module.scss'
import { Ticket } from '../Utilities/types'
import { Modal, Input } from 'antd'

const { TextArea } = Input

const TicketItem: React.FC<{
  ticket: Ticket
  isModalVisible: boolean
  onResolve: (comments: string) => void
  onModalToggle: () => void
}> = ({ ticket, isModalVisible, onResolve, onModalToggle }) => {
  const [comments, setComments] = useState('')

  const handleOk = () => {
    onResolve(comments)
    setComments('')
    onModalToggle()
  }

  const handleCancel = () => {
    onModalToggle()
    setComments('')
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            <h5>{ticket.ticketTitle}</h5>
            <div className={styles.userEmail}>EMAIL: {ticket.userEmail}</div>
            <div className={styles.date}>{ticket.created}</div>
          </div>
          <div className={`${styles.status} ${styles[ticket.status.replace(' ', '-')]}`}>
            {ticket.status}
          </div>
        </div>
        <div className={styles.description}>
          <p>{ticket.ticketDescription}</p>
        </div>
        {ticket.status === 'resolved' && (
          <div className={styles.comment}>
            <h6>Admin Comment</h6>
            <p>{ticket.comment}</p>
          </div>
        )}
        {ticket.status === 'open' && (
          <button onClick={onModalToggle}>Resolve Ticket</button>
        )}
      </div>
      {isModalVisible && (
        <Modal
          title="Resolve Ticket"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Resolve"
          cancelText="Cancel"
          centered
          wrapClassName={styles['modal-wrapper']}
          closable={false}
        >
          <TextArea
            rows={4}
            placeholder="Enter your comments"
            value={comments}
            onChange={e => setComments(e.target.value)}
          />
        </Modal>
      )}
    </>
  )
}

export default TicketItem
